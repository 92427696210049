import { FontAwesome5 } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { Pressable, Image, ActivityIndicator } from 'react-native';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps, Player } from '../types';
import { styles } from '../styles'
import { supabase } from '../services/supabase'
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTailwind } from 'tailwind-rn';
import { useGameStore } from '../store'

export default function Lobby({ navigation }: RootTabScreenProps<'Lobby'>) {
  const tailwind = useTailwind();
  const { loading, fetchPlayers, player, players, setPlayers, updateGameStatus } = useGameStore()
  const game = useGameStore((state) => state.currentGame)

  const receiveNewPlayer = (newPlayer: Player) => {
    fetchPlayers()
  }

  const startGame = () => {
    updateGameStatus('submit_words')
  }
  useEffect(() => {
    if (game) {
      fetchPlayers()

      const waitForNewPlayers = supabase
        .from(`players:game_id=eq.${game?.game_id}`)
        .on('INSERT', payload => {
          if (payload.new) {
            receiveNewPlayer(payload.new);
          }
        })
        .subscribe((status: any) => {
          console.log('status is ' + JSON.stringify(status));
        });

      const watchGameStatus = supabase
        .from(`games:game_id=eq.${game?.game_id}`)
        .on('UPDATE', payload => {
          if (payload.new) {
            if (payload.new.status == 'submit_words') {
              navigation.navigate('SubmitWords')
            }
          }
        })
        .subscribe((status: any) => {
          console.log('status is ' + JSON.stringify(status));
        });

      return () => {
        waitForNewPlayers.unsubscribe()
        watchGameStatus.unsubscribe()
      }
    }
    else {
      navigation.navigate('StartGame')
    }
  }, []);

  return (
    <SafeAreaView style={tailwind('h-full bg-red-500')}>
      <View style={tailwind('pt-5 items-center bg-red-500')}>
        <Text style={tailwind('text-3xl font-bold')}>Game Lobby</Text>
        <View style={[styles.contentContainer]}>
          <View style={tailwind('bg-red-500 p-3 rounded-lg w-48')}>
            <Text style={styles.gameCodeText} >Your Game Code* is: <Text style={{ fontWeight: 'bold' }}>{game?.game_code}</Text></Text>
          </View>
          <Text style={styles.helpText}>*Share this code with everyone else so that they can join the game!</Text>
          <View style={styles.separator}></View>
          <Text style={tailwind('text-red-500 font-bold')}>Who is here?</Text>

          {players.map(p => {
            return (<View style={styles.playerContainer} key={p?.player_id}>
              {p?.host && (<FontAwesome5
                name="star"
                solid
                size={12}
                color={'#DC1A21'}
                style={{}}
              />)}
              <Text style={styles.playerName}>{p?.name}</Text>
            </View>);
          })}

          <View style={styles.separator}></View>

          {player?.host && (
            <View style={tailwind('items-center')}>
              <Text style={tailwind('text-red-500 mt-6 mb-6')}>When everybody is ready... click start game!</Text>
              <Pressable style={tailwind('bg-red-500 p-3 rounded-lg')} onPress={startGame}>
                <Text style={tailwind('font-bold text-xl')}>Start Game</Text>
              </Pressable>
            </View>)}

          {!player?.host && (
            <View>
              <Text style={tailwind('text-red-500 mt-6 mb-6')}>Wait for the host to start the game!</Text>
              <ActivityIndicator color='red' style={tailwind('text-red-500')} size="large"></ActivityIndicator>
            </View>)}
        </View>
      </View>
    </SafeAreaView >
  );
}