import create from 'zustand'
import { Game, Player, Word } from '../types';
import GameService from '../services/games'

interface PlasteredState {
    loading: boolean,
    currentGame: Game | undefined,
    players: Array<Player>,
    player: Player | undefined,
    words: Array<Word>,
    fetchPlayers: () => void,
    setPlayers: (players: Array<Player>) => void,
    createGame: (playerName: string) => void,
    updateGameStatus: (status: string) => void,
    joinGame: (gameCode: string, playerName: string) => void,
    addWordToGame: (word: string) => void,
    confirmWordsSubmitted: () => void,
    reset: () => void
}

export const useGameStore = create<PlasteredState>()((set, get) => ({
    loading: false,
    currentGame: undefined,
    players: [],
    player: undefined,
    words: [],
    updateGameStatus: async (status) => {
        await GameService.updateGameStatus(get().currentGame?.game_id || "", status)
    },
    setPlayers: async (players) => {
        set({ players })
    },
    fetchPlayers: async () => {
        const game = get().currentGame
        if (game) {
            const players = await GameService.getPlayers(game.game_id)
            if (players) {
                set({ players })
            }
        }
    },
    createGame: async (playerName) => {
        set({ loading: true })
        const game = await GameService.createGame()
        if (game) {
            const player = await GameService.addPlayerToGame(game.game_id, playerName, true)
            set({ currentGame: game, player })
        }

        set({ loading: false })
    },
    joinGame: async (gameCode, playerName) => {
        set({ loading: true })

        const response = await GameService.joinGame(gameCode, playerName);
        if (response && response.game && response.player) {
            set({ currentGame: response.game, player: response.player })
        }

        set({ loading: false })
    },
    addWordToGame: async (word) => {
        await GameService.submitWord(get().currentGame?.game_id || "", get().player?.player_id || "", word)
    },
    confirmWordsSubmitted: async () => {
        await GameService.confirmWordsSubmitted(get().player?.player_id || "")
    },
    reset: async () => {
        set({ currentGame: undefined, players: [] })
    }
}))
