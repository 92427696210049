import { supabase } from './supabase'
import { customAlphabet } from 'nanoid/non-secure'
import { Game, Player, Word } from '../types';

const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4)

const GameService = {
    async createGame(): Promise<Game | undefined> {
        try {
            var newGame = {
                game_code: nanoid(),
                status: 'waiting_for_players',
            };

            const { data, error } = await supabase
                .from('games')
                .insert([newGame]);

            if (error) {
                throw error
            }

            if (data && data[0]) {
                return data[0]
            }
        }
        catch (error) {
            console.log(`Error creating game: ${error}`);
            console.log(error);
        }
    },
    async getGame(id: string): Promise<Game | undefined> {
        try {
            let { data, error, status } = await supabase
                .from('games')
                .select(`*`)
                .eq('id', id)
                .single()

            if (error && status !== 406) {
                throw error
            }

            return data;
        } catch (error) {
            console.log(`Error getting game: ${error}`);
        }
    },
    async joinGame(gameCode: string, name: string): Promise<{ player: Player | undefined, game: Game } | undefined> {
        try {
            let { data, error, status } = await supabase
                .from('games')
                .select(`*`)
                .eq('game_code', gameCode)
                .single()

            if (error && status !== 406) {
                throw error
            }

            const game = data

            if (game && game.status == "waiting_for_players") {
                const player = await this.addPlayerToGame(data.game_id, name, false);
                return { player, game: data };
            }
            else {
                throw new Error("Game not found!");
            }
        } catch (error) {
            console.log(`Error getting game: ${error}`);
        }
    },
    async addPlayerToGame(gameId: string, name: string, host: boolean): Promise<Player | undefined> {
        try {
            var newPlayer = {
                game_id: gameId,
                name,
                host: host ? true : false
            };

            const { data, error } = await supabase
                .from('players')
                .insert([newPlayer]);

            if (error) {
                throw error
            }

            if (data && data[0]) {
                return data[0]
            }
        }
        catch (error) {
            console.log(`Error adding player to game: ${error}`);
        }
    },
    async getPlayers(gameId: string): Promise<Array<Player> | null> {
        let { data, error, status } = await supabase
            .from('players')
            .select(`*`)
            .eq('game_id', gameId);

        if (error && status !== 406) {
            throw error
        }

        return data;
    },
    async getWords(gameId: string): Promise<Array<Word> | null> {
        let { data, error, status } = await supabase
            .from('words')
            .select(`*`)
            .eq('game_id', gameId);

        if (error && status !== 406) {
            throw error
        }

        return data;
    },
    async submitWord(gameId: string, playerId: string, word: string): Promise<Word | undefined> {
        try {
            var newWord = {
                game_id: gameId,
                submitted_by: playerId,
                word
            };

            const { data, error } = await supabase
                .from('words')
                .insert([newWord]);

            if (error) {
                throw error
            }

            if (data && data[0]) {
                return data[0]
            }
        }
        catch (error) {
            console.log(`Error word to game: ${error}`);
        }
    },
    async updateGameStatus(gameId: string, status: string): Promise<void> {
        try {
            const { data, error } = await supabase
                .from('games')
                .update({ status })
                .match({ game_id: gameId })

            if (error) {
                throw error
            }
        }
        catch (error) {
            console.log(`Error updating game status: ${error}`);
        }
    },
    async confirmWordsSubmitted(playerId: string): Promise<void> {
        try {
            const { data, error } = await supabase
                .from('players')
                .update({ words_submitted: true })
                .match({ player_id: playerId })

            if (error) {
                throw error
            }
        }
        catch (error) {
            console.log(`Error updating words_submitted on player: ${error}`);
        }
    }
}

export default GameService;