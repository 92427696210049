import { Dimensions, StyleSheet } from "react-native";

const win = Dimensions.get('window');
const ratio = (win.width) / 843; //843 is actual image width

const styles = StyleSheet.create({
    loading: {
        backgroundColor: 'none',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 25,
        backgroundColor: '#DC1A21',
        fontFamily: 'space-mono'
    },
    contentContainer: {
        maxWidth: 650,
        flexGrow: 1,
        alignItems: 'center',
        backgroundColor: '#FFF',
        borderColor: '#DDD',
        borderWidth: 1,
        width: win.width * .95,
        padding: 20,
        margin: 25,
        borderRadius: 10
    },
    footer: {
        position: 'absolute',
        bottom: '5%',
        backgroundColor: '#DC1A21',
        color: '#FFF'
    },
    logo: {
        width: 843 * ratio * .55,
        height: (324 * ratio) * .55, //324 is actual height of image
        maxHeight: 162,
        maxWidth: 422.5
    },
    helpText: {
        fontSize: 10,
        marginTop: 5,
        width: 200,
        color: 'gray',
        textAlign: 'center',
    },
    versionText: {
        fontSize: 10,
        width: 200,
        textAlign: 'center',
        color: '#DC1A21',
        position: 'absolute',
        bottom: 5
    },
    separator: {
        marginVertical: 5,
        height: 1,
        backgroundColor: '#DC1A21',
        width: '80%',
    },
    infoButton: {
        position: 'absolute',
        top: '7%',
        right: '5%'
    },
    link: {
        textDecorationLine: 'underline'
    },
    gameCodeContainer: {
        backgroundColor: '#DC1A21',
        padding: 15,
        width: 200,
        borderRadius: 10,
        marginBottom: 0
    },
    gameCodeText: {
        fontSize: 15,
        color: '#FFFF',
        textAlign: 'center'
    },
    playerContainer: {
        padding: 10,
        backgroundColor: '#FFF',
        borderColor: '#DC1A21',
        borderWidth: 1,
        borderRadius: 10,
        margin: 5,
        flexDirection: 'row',
        alignItems: 'center',
    },
    playerName: {
        color: '#DC1A21'
    },
    disabled: {
        opacity: .55
    }
});

export { styles }