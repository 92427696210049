import { FontAwesome5 } from '@expo/vector-icons';
import { ActivityIndicator, Pressable, Image, TextInput } from 'react-native';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { styles } from '../styles'
import { useState, useEffect } from 'react';
import { Platform, KeyboardAvoidingView } from 'react-native';
import { useTailwind } from 'tailwind-rn';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useGameStore } from '../store'
import { supabase } from '../services/supabase'
import { RealtimeSubscription } from '@supabase/realtime-js';

export default function StartGame({ navigation }: RootTabScreenProps<'SubmitWords'>) {
  const game = useGameStore((state) => state.currentGame)
  const { loading, players, player, addWordToGame, fetchPlayers, confirmWordsSubmitted, updateGameStatus } = useGameStore()

  const [word, setWord] = useState("")
  const [wordCount, setWordCount] = useState(0)

  const tailwind = useTailwind()

  const submitWord = async () => {
    if (word) {
      const newWordCount = wordCount + 1

      addWordToGame(word)
      setWord('')
      setWordCount(wordCount + 1)

      if (newWordCount === 10) {
        confirmWordsSubmitted()
      }
    }
  };

  useEffect(() => {
    fetchPlayers()
    let watchPlayers: RealtimeSubscription | undefined = undefined

    if (player?.host) {
      watchPlayers = supabase
        .from(`players:game_id=eq.${game?.game_id}`)
        .on('UPDATE', payload => {
          if (payload.new) {
            fetchPlayers()
          }
        })
        .subscribe((status: any) => {
          console.log('status is ' + JSON.stringify(status));
        });


    }

    const watchGameStatus = supabase
      .from(`games:game_id=eq.${game?.game_id}`)
      .on('UPDATE', payload => {
        if (payload.new) {
          if (payload.new.status == 'round_1') {
            alert('lets go')
          }
        }
      })
      .subscribe((status: any) => {
        console.log('status is ' + JSON.stringify(status));
      });

    return () => {
      if (watchPlayers) {
        watchPlayers.unsubscribe()
      }

      watchGameStatus.unsubscribe()
    }
  }, []);

  useEffect(() => {
    if (players.every(p => p.words_submitted)) {
      updateGameStatus('round_1')
    }
  }, [players])

  return (

    <View style={tailwind('flex pt-5 bg-red-500 h-full')}>
      <SafeAreaView style={tailwind('items-center')}>
        {loading && (
          <View style={tailwind('items-center justify-center bg-red-500 h-full w-full')}>
            <ActivityIndicator color={'#FFF'} size={'large'}></ActivityIndicator>
          </View>
        )}
        {wordCount < 10 && (
          <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={tailwind('justify-center items-center bg-white p-8 rounded-lg m-5')}>
            <FontAwesome5
              name="keyboard"
              size={55}
              style={tailwind('text-red-500 mb-2')}
            />
            <Text style={tailwind('text-red-500 text-center font-bold')}>Submit 10 words to be guessed in the game!</Text>
            <TextInput style={tailwind('border border-gray-200 bg-gray-200 text-center p-3 rounded-lg mb-2 mt-2 font-bold w-60')} value={word} autoCorrect={false} placeholderTextColor={'gray'} placeholder={'Enter a funny or clever word'} onChangeText={(value) => setWord(value)}></TextInput>
            <Text style={tailwind('text-red-500 mt-2 mb-5 text-center')}>{wordCount} of 10 words submitted</Text>
            <Pressable style={[tailwind('bg-red-500 mt-1 p-3 mb-5 rounded-lg items-center'), { width: 150 }, word ? {} : styles.disabled]} onPress={submitWord}>
              <Text style={tailwind('font-bold')}>Submit Word</Text>
            </Pressable>
          </KeyboardAvoidingView>)}
        {wordCount >= 10 && (
          <View style={tailwind('justify-center items-center bg-white p-8 rounded-lg m-5 text-center')}>
            <FontAwesome5
              name="star"
              size={55}
              style={tailwind('text-red-500 mb-2')}
            />
            <Text style={tailwind('text-red-500 text-center mb-5')}>Sit back and wait for the game to begin!</Text>
            <ActivityIndicator style={tailwind('mb-5 text-red-500')} size='large'></ActivityIndicator>
          </View>)}
        {/* <View style={tailwind('bg-white flex flex-row rounded-lg p-5 ')}>
          {players.map((player, i) => (
            <View key={`${i}-tile`} style={[tailwind(' m-2 justify-center items-center bg-lime-400 border-2 border-slate-700 p-1 rounded-lg text-center'), { width: 75 }]}>
              <FontAwesome5
                name="user"
                size={20}
                style={tailwind('mb-1')}
              />
              <Text style={tailwind('text-black font-bold')}>{player.name}</Text>

            </View>
          ))}
        </View> */}
      </SafeAreaView>
    </View >
  )
}