import { FontAwesome5 } from '@expo/vector-icons';
import { ActivityIndicator, Pressable, Image, TextInput } from 'react-native';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { styles } from '../styles'
import { useState, useEffect } from 'react';
import { Platform, KeyboardAvoidingView } from 'react-native';
import { useTailwind } from 'tailwind-rn';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useGameStore } from '../store'

export default function StartGame({ navigation }: RootTabScreenProps<'StartGame'>) {

  const { loading, createGame, joinGame, reset, currentGame } = useGameStore()
  const [name, setName] = useState("");
  const [gameCode, setGameCode] = useState("");
  const tailwind = useTailwind();

  const startGame = async () => {
    if (name) {
      await createGame(name)
      navigation.navigate("Lobby")
    }
    else {
      alert('put yo name is you silly goose')
    }
  };

  const joinExistingGame = async () => {
    if (name && gameCode) {
      await joinGame(gameCode.toUpperCase(), name)
      navigation.navigate("Lobby")
    }
  };

  useEffect(() => {
    reset()
  }, []);

  return (
    <View style={tailwind('pt-5 bg-red-500 h-full')}>
      <SafeAreaView style={tailwind('items-center')}>
        <Pressable
          onPress={() => navigation.navigate('Modal')}
          style={styles.infoButton}>
          <FontAwesome5
            name="info-circle"
            size={25}
            color={'#FFF'}
          />
        </Pressable>
        <Image style={styles.logo} source={require('../assets/images/plastered-logo.png')} />
        {loading && (
          <View style={tailwind('items-center justify-center bg-red-500 h-full w-full')}>
            <ActivityIndicator color={'#FFF'} size={'large'}></ActivityIndicator>
          </View>
        )}
        {!loading && (
          <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={tailwind('justify-center items-center bg-white p-8 rounded-lg m-5 text-center')}>
            <FontAwesome5
              name="hand-paper"
              size={55}
              style={tailwind('text-red-500 mb-2')}
            />

            <Text style={tailwind('font-bold text-lg text-red-500')}>If you are the host tonight...</Text>
            <Text style={tailwind('font-bold text-lg text-red-500')}>start a new game!</Text>
            <TextInput style={tailwind('border border-gray-200 bg-gray-200 text-center p-3 rounded-lg mb-2 mt-2 font-bold w-60')} placeholderTextColor={'gray'} autoCorrect={false} placeholder={'Enter your name'} onChangeText={(value) => setName(value)}></TextInput>
            <TextInput style={tailwind('border border-gray-200 bg-gray-200 text-center p-3 rounded-lg mb-2 mt-2 font-bold w-60')} placeholderTextColor={'gray'} placeholder={'Enter Game Code'} autoCapitalize={"characters"} autoCorrect={false} maxLength={4} onChangeText={(value) => setGameCode(value)}></TextInput>

            <Pressable style={[tailwind('bg-red-500 mt-1 p-3 rounded-lg items-center'), { width: 150 }, name ? {} : styles.disabled]} onPress={startGame}>
              <Text style={tailwind('font-bold')}>Start New Game</Text>
            </Pressable>

            <Pressable style={[tailwind('border border-red-500 mt-1 p-3 mb-5 rounded-lg items-center'), { width: 150 }, gameCode ? {} : styles.disabled]} onPress={joinExistingGame}>
              <Text style={tailwind('font-bold text-red-500')}>Join Game*</Text>
            </Pressable>
          </KeyboardAvoidingView>)}

      </SafeAreaView>
    </View>
  )
}